/** @jsxImportSource react */
import { shouldShowBackgroundCheck } from '@common/helpers/community';
import { phoneFormatter, phoneParser } from '@common/helpers/phone';
import { GwizzyCommunity } from '@common/helpers/transformGwizzyCommunity';
import {
  composeValidators,
  email,
  required,
  usPhone,
} from '@react/form/helpers/validation';
import {
  TocComponent,
  TocEmailComponent,
} from '@react/gwizzy/components/GwizzyTocComponent';
import TipComponent from '@react/gwizzy/components/TipComponent';
import {
  ACTIONS as actions,
  CONVERSION_FIELDS as fields,
  CONVERSION_STEPS_MAP as steps,
} from '@react/gwizzy/constants';
import { isSelf, shouldAskResNameInPreConv } from '@react/gwizzy/helpers/utils';

import { FormSchema } from '../types';

export function getNonD2CSchema({
  community,
  showLocationStep = false,
  isReadOnlyEmail = false,
  isChatMode = false,
  isResidentNameQuestionRequired,
}: {
  community?: GwizzyCommunity;
  showLocationStep?: boolean;
  isReadOnlyEmail?: boolean;
  isChatMode?: boolean;
  isResidentNameQuestionRequired?: boolean;
}): FormSchema {
  const submitText = community ? 'Send me pricing' : 'Get recommendations';
  const shouldCaptureEmailLead = true;
  const homeSaleQuestion =
    community?.homeSaleQuestionType as keyof typeof steps;

  const hasRadio = !isChatMode;

  const showBackgroundCheck = shouldShowBackgroundCheck(
    community?.state,
    community?.care
  );

  return {
    [steps.lookingFor]: {
      form: {
        getQuestion: () =>
          'Are you looking for senior living options for yourself or someone else?',
        getActions: () => ({
          onNext: {
            goTo: (values) =>
              showLocationStep
                ? steps.userLocation
                : isSelf(values)
                ? steps.budgetRange
                : steps.name,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.lookingFor.options,
            name: fields.lookingFor.name,
            type: 'singleChoice',
            validate: required,
            hasRadio: !isChatMode,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              Answering a few questions will help our advisors give you more
              personalized pricing and recommendations.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.userLocation]: {
      form: {
        getQuestion: () => 'Where are you looking for senior living options?',
        getActions: () => ({
          onNext: {
            goTo: (values) => (isSelf(values) ? steps.budgetRange : steps.name),
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            name: fields.location.name,
            type: 'locationSearch',
            validate: required,
            isUsingDefaultCurrentLocationHandler: false,
            types: ['administrative_area_level_3', 'locality', 'postal_code'],
            placeholder: 'Enter your city or zip code',
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            This will help us find communities that are close to you and your
            loved one.
          </TipComponent>
        ),
      },
    },
    [steps.budgetRange]: {
      form: {
        getQuestion: () =>
          'What is the total cost of care you believe you can afford?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.name,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: (_) => fields.budgetRange.options,
            name: fields.budgetRange.name,
            type: 'singleChoice',
            hasRadio,
            onChangeHandler: ({ useForm }) => {
              const { change } = useForm;
              change(fields.budget.name, null);
            },
            validate: required,
          },
        ],
        footer: {
          FormFooter: () => (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              Less than 2% of communities in your area are less than $2,000. A
              larger budget will give you more options.
            </div>
          ),
        },
      },
    },
    [steps.name]: {
      form: {
        getQuestion: () => 'What is your first and last name?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.email,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fieldWrapperClassName: isChatMode
          ? 'space-y-2 mb-4'
          : 'flex flex-col md:flex-row gap-3 md:child:w-1/2',
        fields: [
          {
            name: 'firstName',
            type: 'text',
            label: 'First name',
            placeholder: 'First name',
            validate: required,
          },
          {
            name: 'lastName',
            type: 'text',
            label: 'Last name',
            placeholder: 'Last name',
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            Let us know your real name. We are real people helping real people
          </TipComponent>
        ),
      },
    },
    [steps.email]: {
      form: {
        getQuestion: () => 'What is your email address?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.phone,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
            asyncHandlerProps: {
              shouldSendUUIDAction: shouldCaptureEmailLead,
            },
            validations: [{ name: 'email', error: 'Invalid email detected' }],
          },
        }),
        fields: [
          {
            name: 'email',
            type: 'email',
            label: 'Email address',
            placeholder: 'Email address',
            validate: composeValidators(email, required),
            readOnly: isReadOnlyEmail,
          },
        ],
        footer: {
          FormFooter: () => <TocEmailComponent />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            We will send you pricing information over email.
          </TipComponent>
        ),
      },
    },
    [steps.phone]: {
      form: {
        getQuestion: () => 'What is your phone number? ',
        getActions: () => ({
          onNext: {
            goTo: () =>
              steps[homeSaleQuestion]
                ? steps[homeSaleQuestion]
                : steps.residentName,
            getType: (values) => {
              return steps[homeSaleQuestion] ||
                shouldAskResNameInPreConv(
                  values,
                  isResidentNameQuestionRequired
                )
                ? actions.setActivePage
                : actions.submit;
            },
            text: submitText,
            asyncHandlerProps: {
              shouldCreateUser: true,
              shouldSendUUIDAction:
                !!steps[homeSaleQuestion] || isResidentNameQuestionRequired,
            },
            variant: 'primary',
            validations: [
              { name: 'phone', error: 'Fake phone number detected' },
            ],
          },
        }),
        fields: [
          {
            name: 'phone',
            type: 'text',
            label: 'Phone number',
            validate: composeValidators(usPhone, required),
            parse: phoneParser,
            format: phoneFormatter,
          },
        ],
        footer: {
          FormFooter: () => <TocComponent submitText={submitText} />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            In case we need more information about you we will reach out by
            phone
          </TipComponent>
        ),
      },
    },
    [steps.homeSaleInterest]: {
      form: {
        getQuestion: () => 'Are you planning to sell your home?',
        formLabel:
          'Over 70% of older adults will use funds from the sale of a home to help fund the transition to senior living.',
        getActions: () => ({
          onNext: {
            goTo: () =>
              showBackgroundCheck ? steps.backgroundCheck : steps.residentName,
            getType: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              ) || showBackgroundCheck
                ? actions.setActivePage
                : actions.submit;
            },
            getText: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              ) || showBackgroundCheck
                ? 'Continue'
                : 'Submit';
            },
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.homeSaleInterest.options,
            name: fields.homeSaleInterest.name,
            type: 'boxChoice',
            hasRadio: true,
            validate: required,
          },
        ],
      },
    },
    [steps.homeSaleSpecialist]: {
      form: {
        getQuestion: () =>
          'Would you be interested in working with a Senior Move Specialist?',
        formLabel:
          'A senior move specialist can coordinate both the sale of your home and the move to senior living.',
        getActions: () => ({
          onNext: {
            goTo: () =>
              showBackgroundCheck ? steps.backgroundCheck : steps.residentName,
            getType: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              ) || showBackgroundCheck
                ? actions.setActivePage
                : actions.submit;
            },
            getText: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              ) || showBackgroundCheck
                ? 'Continue'
                : 'Submit';
            },
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.homeSaleSpecialist.options,
            name: fields.homeSaleSpecialist.name,
            type: 'boxChoice',
            hasRadio: true,
            validate: required,
          },
        ],
      },
    },
    [steps.backgroundCheck]: {
      form: {
        getQuestion: () =>
          'Are you interested in buying background check report for this community?',
        formLabel:
          "Seniorly offers a paid $10 service to provide you a report of a community's license status and inspection reports. This report will highlight any abuse and neglect at the facility, assuring you whether or not the facility adheres to recognized state guidelines.",
        getActions: () => ({
          onNext: {
            goTo: () => steps.residentName,
            getType: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? actions.setActivePage
                : actions.submit;
            },
            getText: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? 'Continue'
                : 'Submit';
            },
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.backgroundCheck.options,
            name: fields.backgroundCheck.name,
            type: 'boxChoice',
            hasRadio: true,
            validate: required,
          },
        ],
      },
    },
    [steps.residentName]: {
      form: {
        getQuestion: () =>
          'What is the name of the person you are searching for?',
        getActions: () => {
          return {
            onNext: {
              type: actions.submit,
              text: 'Submit',
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            name: 'residentName',
            type: 'text',
            label: 'Resident Name',
            validate: required,
          },
        ],
      },
    },
  };
}
